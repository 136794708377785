import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

import homeRoutes from './homeRoutes'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  ...homeRoutes,
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
         return {selector: to.hash }
      }
    }
})

const DEFAULT_TITLE = 'Mathison Projects Inc'
router.afterEach((to: any, from: any) => {
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE + ' | ' + to.meta.title || DEFAULT_TITLE
  })
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
