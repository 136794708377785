<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { parseJwt } from '@/plugins/helpers.ts'

export default {
  computed: {
    ...mapGetters("auth", ["token"])
  },
  mounted() {
    this.validateJWT();
  },
  methods: {
    ...mapMutations("auth", ["SET_TOKEN"]),
    validateJWT() {
      if (this.token) {
        const decoded = parseJwt(this.token)
        const current_time = new Date().getTime() / 1000

        if (current_time > decoded.exp) {
          this.SET_TOKEN("");
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Miltonian+Tattoo&family=Montserrat:wght@300;700&display=swap');

:root {
  font-size: 12px;

  @include screen('md') {
    font-size: 14px;
  }

  @include screen('lg') {
    font-size: 22px;
  }

  @include screen('xl') {
    font-size: 28px;
  }

  @include screen ('uw') {
    font-size: 38px;
  }
}
</style>
p