<template>
  <div class="section-item">
    <line-on-sides class="section-title">
      {{ title }}
    </line-on-sides>
    <v-row class="web-app-services__content content" justify="center">
      <v-col cols="12" md="7">
        <ul class="web-app-services__list">
          <template v-for="link in links">
            <li :key="link.id">
              <router-link :to="link.href">
                  <span class="hover-underline-animation">
                    {{ link.text }}
                  </span>
              </router-link>
            </li>
          </template>
        </ul>
      </v-col>
      <v-col cols="12" md="5">
        <div class="image-container">
          <img :src="$getMediaURL(media.url)" alt="illustration"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LineOnSides from "@/components/ui/LineOnSides";

export default {
  name: 'WebAppServices',
  props: {
    title: String,
    media: Object,
    links: Array
  },
  components: {
    LineOnSides,
  }
}
</script>