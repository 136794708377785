import gql from "graphql-tag";

export const requestQueryAboutPageContent = gql`
  query FetchAboutPageContent {
    aboutPage {
      id
      page_title
      page_slogan
      intro_message
      sections {
        id
        title
        description
        media {
          url
        }
      }
      leadership {
        id
        avatar {
          url
        }
        name
        title
      }
      focus_items {
        id
        title
        description
        isRight
        media {
          url
        }
      }
      our_values {
        id
        title
        description
        background_color
        media {
          url
        }
      }
    }
  }
`;
