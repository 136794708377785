import { apolloClient } from "@/addons";
import { mutationCreateContactUs } from "@/gqlRequests/homepage/contactus";

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createContactUsForm(_: any, payload: any) {
      const response = await apolloClient
        .mutate({
          mutation: mutationCreateContactUs,
          variables: {
            input: {
              data: {
                ...payload
              }
            }
          }
        })
    }
  },
}