<template>
<span>
    <welcome-section/>
  <div>
    <about-us-section/>
    <trusted-by :clients="clientsList" />
<!--    <clients-section :clientsList="clientsList"/>-->
    <newest-teammates-section
        v-if="newhiresList.length"
        :newhiresList="newhiresList"
    />
    <div style="position: relative;" >
      <press-section :articles="articles"/>
      <testimonial-section />
    </div>
    <scroll-to-top-button />
  </div>
  </span>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import AboutUsSection from "@/components/pages/home/AboutUsSection";
import WelcomeSection from "@/components/pages/home/WelcomeSection.vue";
import ScrollToTopButton from "@/components/ui/ScrollToTopButton";
import NewestTeammatesSection from "@/components/pages/home/NewestTeammatesSection";
import TrustedBy from "@/components/pages/home/about-us/TrustedBy";
import PressSection from "@/components/pages/home/about-us/PressSection";
import TestimonialSection from "@/components/pages/home/TestimonialSection";
import axios from 'axios';

export default {
  name: "Basetrusted-byComponent",
  components: {
    TestimonialSection,
    PressSection,
    TrustedBy,
    NewestTeammatesSection,
    ScrollToTopButton,
    WelcomeSection,
    AboutUsSection,
  },
  model: {
    AboutUsSection: "updateModel",
  },
  data() {
    return {
      newhiresList: []
    };
  },
  watch: {
    $route(to, from) {
      if (to.fullPath === '/#clients') {
        this.scrollToClients()
      }
    }
  },
  computed: {
    ...mapGetters("clientsStore", {
      clientsList: "shownClientsList",
    }),
    ...mapGetters("teammateStore", {
      activeMembers: "getActiveTeammates",
    }),
    ...mapGetters("articlesStore", {
      articles: "getAllArticles"
    }),
    ...mapGetters("commonContent", ["trustedByList"]),
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    },
  },
  created() {
    this.getInitialData();
  },
  mounted() {
    if (this.$route.hash === "#clients") {
      this.scrollToClients()
    }
  },
  methods: {
    ...mapActions("coreServicesStore", ["getCoreServices"]),
    ...mapActions("teammateStore", ["getTeammates"]),
    ...mapActions("articlesStore", ["getArticles"]),
    ...mapActions("commonContent", ["getTrustedByList"]),
    ...mapActions("trusted-byStore", ["getContent"]),
    ...mapActions("clientsStore", ["getClients"]),
    async getInitialData() {
      try {
        this.getContent();
        this.getArticles();
        this.getTeammates().then((newhiresList) => (this.newhiresList = newhiresList));
        this.getTrustedByList();
        this.getClients();
      } catch (error) {
        console.error(error);
      }
    },
    scrollToClients() {
      this.$vuetify.goTo("#clients", {
        offset: 0,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@include screen('md') {
  div {
    overflow-x: hidden;
    margin: 0 5vw;
  }
}
</style>
