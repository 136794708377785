<template>
  <div class="line-on-sides">
    <h2>
      <slot></slot>
    </h2>
  </div>
</template>

<script>
export default {
  name: "LineOnSides",
};
</script>

<style lang="scss" scoped>
h2 {
  margin: 0 auto;
  font-size: 2rem;
  max-width: 50vw !important;

  @media only screen and (max-width: 768px) {
    font-size: 1.75rem;
  }

  &:first-child {
    margin-top: 0;
  }
}
.line-on-sides {
  display: flex;
  text-align: center;
  align-items: center;
}
.line-on-sides:before,
.line-on-sides:after,
.line-on-sides-ie span.after,
.line-on-sides-ie span.before {
  content: "";
  flex-grow: 1;
  margin: 0px 15px;
  background-size: 100% 2px;
  background-position: 0% 50%;
  background-repeat: repeat-x;
}
.line-on-sides:before,
.line-on-sides-ie span.before {
  height: 2px;
  background-image: linear-gradient(to right, #F63C6F, #23E260);
}
.line-on-sides:after,
.line-on-sides-ie span.after {
  height: 2px;
  background-image: linear-gradient(to left, #F63C6F, #23E260);
}

@media (min-width: 768px) {
  h2 {
    font-size: 3rem;
  }
}
</style>
