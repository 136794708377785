<template>
  <Section id="clients"
    color="#fff"
    background="#2C2D3F"
    title="Clients"
           class="text-justify"
    description="Testimonials from our clients are what drive our mission to produce first-in-class products."
  >
    <v-row class="px-md-0 px-6 text-left">
      <v-col
        v-for="client in clientsList"
        :key="client.id"
        cols="12"
        md="4"
        xs="12"
      >
        <testimonial-card :client="client" />
      </v-col>
    </v-row>
  </Section>
</template>

<script>
import Section from '../../ui/Section'
import TestimonialCard from './TestimonialCard.vue'

  export default {
    name: 'ClientsSection',
    components: {
      Section,
      TestimonialCard
    },
    props: {
      clientsList: {
        type: Array || undefined,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>