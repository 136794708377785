import { apolloClient } from "@/addons";
import { requestQueryCareersPageContent } from "@/gqlRequests";

interface IState {
  careersPageContent: ICareersPageContent
}

interface ICareersPageContent {
  id: string;
  page_title: string;
  page_subtitle: string;
  page_illustration: {
    url: string;
  }
}

export default {
  state: {
    careersPageContent: {} as ICareersPageContent
  },
  getters: {
    careersPageContent: (state: IState) => state.careersPageContent
  },
  mutations: {
    SET_CAREERS_PAGE_CONTENT(state: IState, payload: ICareersPageContent) {
      state.careersPageContent = payload
    }
  },
  actions: {
    async getContent(
      { commit }: any,
      payload: any
    ): Promise<ICareersPageContent> {
      try {
        const { data } = await await apolloClient.query({
          query: requestQueryCareersPageContent,
        });

        const { careersPage = {} } = data;

        commit("SET_CAREERS_PAGE_CONTENT", careersPage);

        return careersPage;
      } catch (error: any) {
        throw new Error(error);
      }
    },
  },
};
