import gql from "graphql-tag";

export const requestQueryGetCoreServices = gql`
  query FetchServices($display: Boolean = true) {
    coreServices(where: { display: $display }, sort: "oid:ASC") {
      id
      title
      description
      icon
    }
  }
`;
