interface ISiteContent {
    id: string
    uid: string
    key: string
    content: string
}

interface ISiteContents {
    siteContents: ISiteContent[]
}

export default {
    state: {
        siteContents: []
    },
    mutations: {
        SET_SITECONTENTS(state: ISiteContents, payload: ISiteContent[]) {
            state.siteContents = payload
        }
    },
    actions: {
        setSiteContents({commit}: { commit: any }, payload: ISiteContent[]) {
            commit('SET_SITECONTENTS', payload)
        },
        resetStore({commit}: {commit: any}) {
            commit('SET_SITECONTENTS', [])
        }
    },
    getters: {
        getTermsOfUse(state: ISiteContents) {
            return state.siteContents.filter(content => content.key === 'terms-of-use')[0].content
        },
        getPrivacyPolicy(state: ISiteContents) {
            return state.siteContents.filter(content => content.key === 'privacy-policy')[0].content
        }
    }
}