import gql from "graphql-tag";

export * from "./careers";
export * from "./clients";
export * from "./about";

export const requestQueryGetSkills = gql`
  query FetchSkills {
    skills(sort: "oid:ASC") {
      id
      name
      oid
    }
  }
`;

export const requestQueryHomepageContent = gql`
  query FetchHomepageContent {
    homepage {
      whatWeDoTitle
      whatWeDoDescription
      endToEndAppDevTitle
      endToEndAppDevSubtitle
      endToEndAppDevDescription
      endToEndAppDevMedia {
        url
      }
      webAppServiceLink {
        id
        text
        href
      }
      webAppServiceTitle
      webAppMedia {
        url
      }
      testimonial {
        avatar {
          url
        }
        position
        author
        testimonial
      }
      collaborativeTitle
      collaborativeDescription
      focusItems {
        id
        title
        description
        media {
          url
        }
      }
    }
  }
`;

export const requestQueryServicesPageContent = gql`
  query FetchServicesPageContent {
    servicespage {
      id
      title
      description
      mainMedia {
        id
        url
        name
      }
      services {
        id
        title
        description
        icon
        oid
        display
        href
      }
      service_sections {
        id
        text
        text_class
        title
        show_schedule_button
        media {
          url
        }
        text_order
        media_order
      }
      service_sections_2 {
        id
        text
        text_class
        title
        show_schedule_button
        media {
          url
        }
        text_order
        media_order
      }
    }
  }
`;

export const requestQueryTrustedByList = gql`
  query FetchTrustedByList {
    trustedBy {
      id
      trusted_by_list {
        id
        text
        class
        image {
           url
        }
      }
    }
  }
`;
