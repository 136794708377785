import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import store from '@/store'

const httpLink = createHttpLink({
  uri: `${process.env.VUE_APP_API_URL}/graphql`
})

const authLink = setContext((_, { headers }) => {
  const token = store.getters['auth/token']
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const cache = new InMemoryCache()


export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        locale: localStorage.getItem('locale') || 'en'
      }
    }
  }
})

export const apolloClientFactory = () => {
  const locale = localStorage.getItem('locale') || 'en'

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          locale
        }
      }
    }
  })
}
