<template>
  <section :style="cssProps" :id="name">
    <v-container :style="{ 'max-width': maxWidthContainer }">
      <div v-if="title" class="d-flex justify-center shrink" data-aos="fade-up">
        <div class="text-center display-1 font-weight-bold mb-10 section__title">
          {{ title }}
        </div>
      </div>
      <div v-if="description" class="text-center headline" data-aos="fade-up">
        {{ description }}
      </div>
      <slot></slot>
    </v-container>
    <divider v-if="divider" :style="cssDivider" />
  </section>
</template>

<script>
export default {
  name: "Section",
  components: {
    Divider: () =>
      import(
        /* webpackChunkName: "divider" */
        "./Divider.vue"
      ),
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "#fff",
    },
    backgroundColor: {
      type: String
    },
    description: {
      type: String,
      default: "",
    },
    divider: {
      type: Boolean,
      default: false,
    },
    dividerBackground: {
      type: String,
      default: "#F6F6F6",
    },
    maxWidthContainer: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  computed: {
    cssProps() {
      return {
        color: this.color,
        backgroundColor: this.backgroundColor || '#fff'
      };
    },
    cssDivider() {
      return {
        fill: this.dividerBackground,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  padding: 2rem 0;
  overflow: hidden;
}

.section__title {
  position: relative;
  z-index: 2;
}
.divider {
  fill: #f6f6f6;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 10vh;
  z-index: 1;
}
.container {
  height: 100%;
  max-width: 1200px;
  @media only screen and (min-width: 2000px) {
    margin-left: unset !important;
  }
}

@media (min-width: 768px) {
  section {
    padding: 6rem 0;
  }
}
</style>
