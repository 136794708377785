import { apolloClient } from "@/addons";
import { requestQueryGetTeammates } from "@/gqlRequests";

interface ITeammate {
  id: string;
  name: string;
  description: string;
  bio: string;
  oid: number;
  joined: string;
  active: boolean;
  favorite_tech: string;
}

interface IGetTeammatesPayload {
  joined?: string
}

interface ITeammates {
  teammates: ITeammate[];
}

export default {
  state: {
    teammates: [],
  },
  mutations: {
    SET_TEAMMATES(state: ITeammates, payload: ITeammate[]) {
      state.teammates = payload;
    },
  },
  actions: {
    async getTeammates(
      { commit }: any,
      payload: IGetTeammatesPayload
    ): Promise<ITeammate[]> {
      const response = await apolloClient.query({
        query: requestQueryGetTeammates,
        variables: payload
      });
      const { teamMembers } = response.data;
      commit("SET_TEAMMATES", teamMembers);

      return teamMembers;
    },
    setTeammates({ commit }: { commit: any }, payload: ITeammate[]) {
      commit("SET_TEAMMATES", payload);
    },
    resetStore({ commit }: { commit: any }) {
      commit("SET_TEAMMATES", []);
    },
  },
  getters: {
    getActiveTeammates(state: ITeammates) {
      return state.teammates || [];
    },
  },
};
