<template>
  <Section
    maxWidthContainer="80%"
    title="Our Newest Teammates"
  >
    <v-row align="start" class="newHires">
      <v-col
        v-for="(member, index) in newhiresList"
        :key="member.id"
        cols="12"
        md="4"
        lg="3"
      >
        <newest-teammates-card
          :member="member"
          :index="index"
        />
      </v-col>
    </v-row>
  </Section>
</template>

<script>
import Section from '../../ui/Section.vue'
import NewestTeammatesCard from './NewestTeammatesCard'

  export default {
    name: 'NewestTeammatesSection',
    components: {
      Section,
      NewestTeammatesCard
    },
    props: {
      newhiresList: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.newHires {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>