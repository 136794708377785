<template>
  <Section
      class="service__section"
      background="#F6F6F6"
    >
      <v-row class="mt-3">
        <v-col
          cols="12"
          sm="6"
          md="6"
          class="sticky-column"
          data-aos="fade-right"
        >
          <div class="d-flex justify-center shrink">
        <div
          class="text-center display-2 mb-10 section__title"
        > 
          Our Core Services
        </div>
      </div>
      <div  class="text-center headline">
        We have hands in all leading technology platforms and provide expert software development engineering in a wide array of services.
      </div>
        </v-col>
        <v-col
        >
          <service-card v-for="serviceItem in serviceList"
          :key="serviceItem.id" :service="serviceItem"></service-card>
        </v-col>
      </v-row>
    </Section>
</template>

<script>
import Section from '../../ui/Section'
import ServiceCard from '../services/ServiceCard.vue'

  export default {
    name: 'ServicesSection',
    components: {
      Section,
      ServiceCard
    },
    props: {
      serviceList: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .service__section {
    overflow: hidden;
  }

  .sticky-column {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 4.5rem;
    align-self: flex-start; /* <-- this is the fix */
  }

  @media only screen and (min-width: 600px) {
    .service__section {
      overflow: unset;
    }
  }
</style>