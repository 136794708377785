<template>
  <div class="team-card d-flex flex-column align-center justify-center" :data-aos="dataAOS">
    <div class="team-img">
      <img
        :src="memberImage"
        alt="team member"
      >
    </div>
    <div class="team-content black--text d-flex align-center justify-center flex-column mt-3">
      <p class="text-center my-0 intro-text">
        {{ member.name }}
      </p>
      <p class="text-center my-0 font-weight-bold secondary-text">
        {{ member.title.replace('_', ' ') }}
      </p>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'

  export default {
    name: 'NewestTeammatesCard',
    props: {
      member: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
        default: 0
      }
    },
    computed: {
      memberImage() {
        return this.$getMediaURL(get(this.member, 'user_image.0.url'))
      },
      dataAOS() {
        switch (this.index % 3) {
          case 0:
          default:
            return 'fade-right'
          case 1:
            return 'fade-up'
          case 2:
            return 'fade-left'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.team-card {
  /* width: 100%; */
  padding: 1rem;
  position: relative;
  transition: ease-in-out .4s;
}

.team-card * {
   transition: ease-in-out .4s;
}

.team-card:hover {
 transform: translateY(-25px);
}

.team-img {
  background: white;
  display: flex;
  align-items: center;
}

.team-img img {
  width: 225px;
  height: 225px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
</style>