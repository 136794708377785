import { apolloClient } from "@/addons"
import { requestQueryGetSkills } from "@/gqlRequests"
import { get } from 'lodash'

interface IState {
  skills: ISkill[]
}

interface ISkill {
  name: string
  id: string
  oid: number
}

export default {
  state: {
    skills: []
  },
  getters: {
    skills(state: IState): ISkill[] {
      return state.skills
    }
  },
  mutations: {
    SET_SKILLS(state: IState, payload: ISkill[]): void {
      state.skills = payload
    }
  },
  actions: {
    getSkills({ commit }: any) {
      return new Promise((resolve, reject) => {
        apolloClient
        .query({
          query: requestQueryGetSkills
        })
        .then(({ data }) => {
          const skills = get(data, 'skills', [])
          commit('SET_SKILLS', skills)   
          resolve(skills)
        })
        .catch(error => {
          console.error(error)
          commit('SET_SKILLS', [])
          reject([])
        })
      })
    }
  }
}
