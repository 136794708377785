
<template>
  <div class="testimonial-section" ref="div">
    <div id="testinomialContainer" class="testimonial-wrapper pb-10 px-10">
      <div class="testimonial-container">
        <figure>
          <img
            :style="responsiveImg"
            :src="testimonial.avatar.url"
            alt="testimonial"
          />
          <blockquote v-html="testimonial.testimonial"></blockquote>
          <figcaption>
            <a href="https://twitter.com/mathisonpro">
              {{ testimonial.author }}
            </a>
            {{ testimonial.position }}
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TestimonialSection",
  props: {
    propId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    height: 0,
  }),
  computed: {
    ...mapGetters("homepageStore", ["homepageContent"]),
    testimonial() {
      return this.homepageContent?.testimonial || {};
    },
    /*eslint-disable-next-line*/
    responsiveImg() {
      switch (this.$vuetify.breakpoint.name) {
            case 'xs': return "min-width: 75px; min-height: 75px"
            case 'sm': return "min-width: 125px; min-height: 125px"
            case 'md': return "min-width: 175px; min-height: 175px"
            case 'lg': return "min-width: 250px; min-height: 250px"
            case 'xl': return "min-width: 350px; min-height: 350px"
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.testimonial-section {
  overflow: hidden;
  min-height: 15rem;
  z-index: 2 !important;

  .testimonial-wrapper {
    background: #111111;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    min-width: 100%;
    position: relative;
    @media only screen and (max-width: 425px) {
      right: 0%;
    }
  }

  .testimonial-container {
    background: #111111;
    max-width: 40rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: white;
    padding: 2rem 0;

    img {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      margin: 0px auto 32px;
      user-select: none;
    }

    blockquote {
      position: relative;
      text-align: center;
      margin: 0px 20px;
      padding: 0px 20px;
      quotes: none;
      font-weight: 500;

      &::before {
        content: "“";
        left: -10px;
        top: 10px;
        position: absolute;
        transform: scale(3);
        font-family: "Times New Roman", serif;
        @media only screen and (max-width: 1440px) and (min-width:1024px) {
          left: 1rem;
        }
        @media only screen and (max-width: 1024px) and (min-width:768px) {
          left: 1rem;
        }
      }
      &::after {
        content: "”";
        right: -10px;
        top: 10px;
        position: absolute;
        transform: scale(3);
        font-family: "Times New Roman", serif;
        @media only screen and (max-width: 1440px) and (min-width:1024px) {
          right: 1rem;
        }
        @media only screen and (max-width: 1024px) and (min-width:768px) {
          right: 1rem;
        }
      }
    }

    figcaption {
      color: hsl(223deg, 5%, 60%);
      font-weight: 500;
      font-size: 1em;

      a {
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1em;

        &:hover {
          text-decoration: underline;
        }
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }
  }

  .spacer {
    aspect-ratio: 960/200;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50px;
  }
  .curve-up-wrapper {
    background: rgb(44, 45, 63);
    background: linear-gradient(
      180deg,
      rgba(44, 45, 63, 1) 0%,
      rgba(17, 17, 17, 1) 100%
    );
  }
  .curve-up {
    background-image: url("/testimonial-wrapper-curves-up.svg");
  }

  .curve-down-wrapper {
    background: rgb(17, 17, 17);
    background: linear-gradient(
      180deg,
      rgba(17, 17, 17, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  .curve-down {
    background-image: url("/testimonial-wrapper-curves-down.svg");
  }
}

@media (min-width: 768px) {
  blockquote {
    &::before,
    &::after {
      transform: scale(5) !important;
    }
  }

  .spacer {
    height: 200px !important;
  }

  .testimonial-container {
    padding: 3rem 0 !important;
  }
}

.bottomTriangle {
  height: 10rem; 
  position:absolute; 
  right: 0px;
  z-index: 3;
}
.testimonial-dash {
  background-color: whitesmoke;
  position: relative;
  top: 6%;
  max-width: 20%;
  left: 40%;
}
</style>