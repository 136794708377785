import { apolloClientFactory } from "@/addons"
import { requestQueryGetCoreServices } from "@/gqlRequests/dashboard/coreServices"

interface ICoreService {
  title: string
  description: string
  icon: string
}

export default {
  actions: {
    async getCoreServices(_: any): Promise<ICoreService[]> {
      const response = await apolloClientFactory().query({ query: requestQueryGetCoreServices })
        const { coreServices } = response.data
        return coreServices || []
    }
  }
}