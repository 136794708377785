import { apolloClient } from "@/addons";
import { requestQueryServicesPageContent } from '../../gqlRequests/homepage/index';
interface IState {
  servicesPageContent: IServicesPageContent
}

interface IServicesPageContent {
  id: string;
  title: string;
  description: string;
  mainMedia: {
    id: string;
    url: string;
  }
  services: {
    id: string;
    title: string;
    description: string;
    icon: string;
    oid: number;
    display: boolean;
    href: string;
  }
}

export default {
  state: {
    servicesPageContent: null,
  },
  getters: {
    servicesPageContent(state: IState) {
      return state.servicesPageContent;
    },
  },
  mutations: {
    SET_SERVICESPAGE_CONTENT(state: IState, payload: IServicesPageContent) {
      state.servicesPageContent = payload;
    },
  },
  actions: {
    async getContent({ commit }: any, payload: any): Promise<IServicesPageContent> {
      try {
        const { data } = await await apolloClient.query({
          query: requestQueryServicesPageContent,
        });

        const { servicespage = {} } = data;

        commit("SET_SERVICESPAGE_CONTENT", servicespage);

        return servicespage;
      } catch (error) {
        throw new Error(error);
      }
    },
  }
}