<template>
  <div id="mainContainer">
    <v-container class="d-flex flex-column align-center justify-center my-5" fluid>
      <h2 class="text-center title-text font-weight-bold">Our Products & Services</h2>
      <v-row class="my-5 pandsrow" style="gap: 2.5rem;">
        <v-col>
          <p class="rightP" v-for="service in services.slice(0, services.length / 2)" :key="service.oid"
            @click="showService(service)">
            {{ service.title }}
          </p>
        </v-col>
        <v-col>
          <p class="leftP" v-for="service in services.slice(services.length / 2)" :key="service.oid"
            @click="showService(service)">
            {{ service.title }}
          </p>
        </v-col>
      </v-row>
      <v-btn class="black white--text rounded-pill"
        to="/products-and-services">
        Learn More
      </v-btn>
      <img v-if="!isMobile" src="/img/products-and-services.svg" id="banner" />
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProductsAndServices',
  data: () => ({
    services: []
  }),
  computed: {
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    },
  },
  methods: {
    ...mapActions("coreServicesStore", ["getCoreServices"]),
    showService(item) {
      this.$router.push({name: 'products-and-services', params: {selected: item.id}})
    },
  },
  async mounted() {
    this.services = await this.getCoreServices()
  }
}
</script>
<style lang="scss">
.ultrawide-products-services {
  display: flex;
  flex-direction: column;
}
@include screen('onlySm') {
  .ultrawide-row {
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }
  .ultrawide-col {
    margin-left: 1em;
    margin-right: 1em;
  }
}
@media only screen and (min-width: 2000px) {
  .ultrawide-products-services {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    padding-left: 6vw;
    padding-right: 6vw;
  }
  .ultrawide-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ultrawide-col {
    flex-grow: unset !important;
  }
  .row {
    flex: 0 0 auto !important;
  }
  .ultrawide-header {
    margin-right: 3vw;
    margin-left: -3vw;
  }
}
#mainContainer {
  position: relative;
  z-index: 0;
  p {
    cursor: pointer;
    @include screen('md') {
      white-space: nowrap;
    }
  }
}
#banner {
  position: absolute;
  bottom: -0.73rem;
  right: 0;
  z-index: -1;
}

.pandsrow {
  @media only screen and (max-width: 750px) {
    gap: 0 !important;
  }
}

</style>S