interface IState {
  drawer: boolean
}

export default {
  state: {
    drawer: false
  },
  mutations: {
    toggleDrawer: (state: IState) => { state.drawer = !state.drawer }
  },
  actions: {
  },
  getters: {
    drawer(state: IState){
      return state.drawer
    }
  }
}
