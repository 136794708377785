<template>
  <div class="py-md-16 approach">
    <div class="mb-5 pt-5 pt-lg-0 pb-md-16 mb-md-12 mb-lg-10 title-text font-weight-medium text-center ultrawide-title">
      {{ title }}
    </div>
    <v-container>
      <v-row class="align-center triangle-wrapper ultrawide-fix">
        <v-col cols="7" md="7" xl="5">
          <VueMarkdown class="text-left intro-text">
            {{ description }}
          </VueMarkdown>
        </v-col>
        <v-col cols="5" md="5" class="d-flex align-center justify-center pa-0 triangle-col">
          <triangle-our-approach style="height: 100%" id="triangle" />
        </v-col>
      </v-row>
    </v-container>
    <div id="split-bg-container" class="d-flex flex-column align-center justify-center mb-5">
      <v-container fluid>
        <v-row class="align-center justify-center pa-0">
          <v-col class="pa-md-5" cols="5" >
            <div class="CompleteApproach font-weight-medium mb-10 text-center">The Complete Approach</div>
          </v-col>
          <v-col cols="7" class="text-left" id="complete-approach__description">
            <p class="white--text responsive-text">You may or may not be familiar with terms like “front-end, back-end”, or maybe even
              “API”. In simpler terms,
              Mathison projects covers what our users and customers see and interact with (the front-end), the functions
              and
              brains of the application (the back end), and the connection that ties them together known as the
              API. </p>
            <p class="white--text responsive-text">We
              support our customer needs from start to finish as well as infrastructure requirements to support it if
              needed.</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
import TriangleOurApproach from './TriangleOurApproach.vue'

export default {
  name: 'OurApproach',
  props: {
    title: String,
    description: String
  },
  components: {
    VueMarkdown,
    TriangleOurApproach
  }
}
</script>

<style lang="scss">
#complete-approach__description {
  @include screen('md') {
    padding-left: 4em;
  }
}
.triangle-wrapper {
  @include screen('lg') {
    margin-top: -6rem
  }
}
#triangle {
  max-width: 400px;

  @media only screen and (min-width: 900px) {
    max-width: unset;
  }
}

#split-bg-container {
  background-image: url("/img/the-complete-approach.webp");
  background-size: 105% 100%;
  background-position: -20px;
  min-height: 25vh;

  @include screen('sm') {
    h1 {
      color: white;
    }
    .CompleteApproach {
      font-size:1.4rem;
      margin-bottom:1rem;
      @media only screen and (max-width: 768px) {
        margin-top:1rem;
        font-size:1.6rem;
      }
    }
  }

  @include screen('md') {
    min-height: 50vh;
    background-size: 130%;
    background-position: center center;
  }

  .container {
    height: 100%;
  }

  .col {
    z-index: 2;
  }
}

#split-bg {
  z-index: 1;
}

.responsive-text {
  font-size: .9rem;
  line-height: 100%;
  
  @include screen('md') {
    font-size: 1rem;
  }
}
  @media only screen and (min-width: 2000px) {
  .ultrawide-title {
    padding-bottom: 3% !important;
  }
  .ultrawide-fix {
    display: flex !important;
    width: 100vw;
    padding-left: 6vw;
  }
  .container {
    max-width: 100vw !important;
  }
  .intro-text {
    max-width: 20vw;
  }
  .triangle-col {
    margin-left: -33vw;
    justify-content: unset !important;
    @media only screen and (min-width: 1920px) and (max-height: 800px) and (max-width: 2000px) {
      max-width: 50% !important;
    }
    @include screen('xl') {
      flex: 0 0 83.333333333333%;
      max-width: 83.3333333333333%;
    }
  }
  .approach {
    .triangle-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>