<template>
  <Section class="welcomeSection">
    <div class="white--text d-flex flex-column align-start content pl-4 pl-lg-0 ultrawide-fix">
      <h1 class="font-weight-bold text-uppercase text-h4 white--text">
        If it exists, <br/> chances are we've coded it.
      </h1>
      <h2 class="font-weight-light text-subtitle-1">
        Full-Stack Development from veteran programming experts.
      </h2>
      <v-btn rounded color="teal accent-4" :x-large="$vuetify.breakpoint.mdAndUp"
              class="my-md-16 mt-3 py-md-5 py-2 px-md-10 font-weight-bold" elevation="2" small
             :large="!isMobile" :to="{ path: '/contact-us', hash: '#form' }">
        Contact Us
      </v-btn>
    </div>
  </Section>
</template>

<script>
import Section from "../../ui/Section";

export default {
  name: "WelcomeSection",
  components: {
    Section,
  },
  computed: {
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    },
  },
};
</script>

<style lang="scss" scoped>
.welcomeSection {
  background-image: url(/img/homescreen-desktop-banner.webp);
  background-size: cover;
  background-position: top center;

  .content {
    gap: 1rem;
  }
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
  justify-content: left !important;
}

.contactUsButton {
  @include screen('xl') {
    padding: 1rem !important;
    font-size: 20px;
  }
}

.ultrawide-fix {
  @media only screen and (min-width: 2000px) {
    display: flex !important;
    width: 100vw;
    margin-right: auto;
    margin-left: 10vw;
  }
}
</style>
