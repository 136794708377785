import { apolloClient, apolloClientFactory } from "@/addons";
import {
  requestQueryClientsPageContent,
  requestQueryGetClients,
} from "@/gqlRequests";

interface IClient {
  id: string;
  uid: string;
  name: string;
  description: string;
  logo_image: {
    url: string;
  };
  obtained_by: string;
  oid: number;
  display: boolean;
}
interface IClients {
  clients: IClient[];
  clientsPageContent: IClientsPageContent;
}

interface IClientsPageContent {
  id: string;
  page_title: string;
  page_illustration: {
    url: string;
  };
}

export default {
  state: {
    clients: [],
    clientsPageContent: null,
  },
  getters: {
    clientsPageContent(state: IClients) {
      return state.clientsPageContent || {};
    },
    shownClientsList(state: IClients) {
      return state.clients;
    },
    topSixClients(state: IClients) {
      return state.clients?.slice(0, 6);
    },
  },
  mutations: {
    SET_CLIENTS(state: IClients, payload: IClient[]) {
      state.clients = payload;
    },
    SET_CLIENTS_PAGE_CONTENT(state: IClients, payload: IClientsPageContent) {
      state.clientsPageContent = payload;
    },
  },
  actions: {
    async getClients({ commit }: any) {
      const response = await apolloClientFactory().query({
        query: requestQueryGetClients,
      });
      const { clients } = response.data;
      commit("SET_CLIENTS", clients);
      return clients;
    },
    setClients({ commit }: { commit: any }, payload: IClient[]) {
      commit("SET_CLIENTS", payload);
    },
    resetStore({ commit }: { commit: any }) {
      commit("SET_CLIENTS", []);
    },
    async getPageContent(
      { commit }: any,
      payload: any
    ): Promise<IClientsPageContent> {
      try {
        const { data } = await await apolloClient.query({
          query: requestQueryClientsPageContent,
        });

        const { clientsPage = {} } = data;

        commit("SET_CLIENTS_PAGE_CONTENT", clientsPage);

        return clientsPage;
      } catch (error: any) {
        throw new Error(error);
      }
    },
  },
};
