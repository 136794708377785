import {apolloClient} from "@/addons";
import {mutationCreateApplicant} from "@/gqlRequests";
import {get} from 'lodash';
interface IApplicant {
    id: string
    firstName: string
    lastName: string
    emailAddress: string
    resume: File
    coverLetter: File
}
interface IState {
    applicants: IApplicant[]
}
export default {
    state: {
        applicants: []
    },
    mutations: {
        ADD_APPLICANT(state: IState, payload: IApplicant): void {
            state.applicants.unshift(payload)
        }
    },
    actions: {
        createApplicant({commit}: any, payload: IApplicant): Promise<IApplicant> {
            return new Promise((resolve, reject) => {
                apolloClient.mutate({
                    mutation: mutationCreateApplicant,
                    variables: {
                        input: {
                            data: {
                                ...payload
                            }
                        }
                    }
                })
                    .then(({data}) => {
                        const applicant = get(data, 'createApplicant.applicant', null)
                        if (!applicant) {
                            throw new Error('Error when creating applicant')
                        }
                        commit('ADD_APPLICANT', applicant)
                        resolve(applicant)
                    })
                    .catch(reject)
            })
        }
    }
}