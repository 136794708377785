
interface IState {
  primaryColor: string
}

export default {
  state: {
    primaryColor:  ''
  },

  getters: {},

  mutations: {},

  actions: {}
}
