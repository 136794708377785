import { apolloClient } from "@/addons";
import { requestQueryAboutPageContent } from "@/gqlRequests";

interface IState {
  aboutPageContent: IAboutPageContent
}

interface IAboutPageContent {
  page_title: string;
  page_slogan: string;
  intro_message: string;
  sections: {
    id: number;
    title: string
    description: string;
  }[]
}

export default {
  state: {
    aboutPageContent: {} as IAboutPageContent
  },
  getters: {
    aboutPageContent: (state: IState) => state.aboutPageContent
  },
  mutations: {
    SET_ABOUT_PAGE_CONTENT(state: IState, payload: IAboutPageContent) {
      state.aboutPageContent = payload
    }
  },
  actions: {
    async getContent(
      { commit }: any,
      payload: any
    ): Promise<IAboutPageContent> {
      try {
        const { data } = await await apolloClient.query({
          query: requestQueryAboutPageContent,
        });

        const { aboutPage = {} } = data;

        commit("SET_ABOUT_PAGE_CONTENT", aboutPage);

        return aboutPage;
      } catch (error: any) {
        throw new Error(error);
      }
    },
  },
};
