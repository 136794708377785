import { apolloClient } from "@/addons"
import {
  requestQueryGetUserProfiles
} from '@/gqlRequests'

interface IRole {
  id: string
  name: string
}

interface IAuth {
  token: string
  userId: string
  username: string
  email: string
  didAutoLogout: boolean
  avatar: string
  role: IRole
  ratePerHour: number
  userProfileId: string
}


interface ISetUser {
  jwt: string
  id: string
  username: string
  email: string
  avatar: string
  role: IRole
  ratePerHour: number
  userProfileId: string
}


export default {
  getters: {
    user(state: IAuth): IAuth {
      return {
        ...state
      }
    },
    isAuthenticated(state: IAuth): boolean {
      return !!state.token
    },
    token(state: IAuth): string {
      return state.token
    },
    userId(state: IAuth): string {
      return state.userId
    },
    isAdmin(state: IAuth): boolean {
      return state.role?.name === 'Admin'
    }
  },
  state: {
    token: '',
    userId: '',
    username: '',
    email: '',
    didAutoLogout: false,
    avatar: '',
    role: null,
    ratePerHour: 0,
    userProfileId: '',
  },
  mutations: {
    SET_USER(state: IAuth, payload: ISetUser): void {
      state.token = payload.jwt
      state.userId = payload.id;
      state.username = payload.username;
      state.email = payload.email;
      state.didAutoLogout = false;
      state.role = payload.role
    },
    SET_RATE_PER_HOUR(state: IAuth, payload: number) {
      state.ratePerHour = payload
    },
    SET_TOKEN(state: IAuth, payload: string) {
      state.token = payload
    },
    SET_USER_PROFILE_DATA(state: IAuth, payload: any) {
      state.userProfileId = payload.userProfileId
      state.ratePerHour = payload.ratePerHour
    }
  },
  actions: {
    async getUserProfile({getters, commit}: any = {}) {
      try {
        const userId = getters?.userId
        const { data } = await apolloClient
        .query({ query: requestQueryGetUserProfiles, variables: {
          users_permissions_user: userId
        } })
        const userProfile = data.userProfiles.find((userProfile: any) => userProfile.users_permissions_user.id === userId)
        if (userProfile) {
          const ratePerHour = (userProfile.ratePerHour || 0) * 1
          const userProfileId = userProfile.id
  
          commit('SET_USER_PROFILE_DATA', {
            ratePerHour,
            userProfileId
          })
        }
      } catch (error: any) {
        throw new Error(error)
      }
    }
  }
}
