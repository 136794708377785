<template>
  <v-card class="card__container px-4 py-10" data-aos="fade-left">
    <v-icon class="service__icon" size="50">
      mdi-palette-advanced
    </v-icon>
    <v-card-title class="service__title font-weight-regular text-3">
      {{ service.title }}
    </v-card-title>
    <v-card-text class="mt-3 service__description body-1">
      <p class="ma-0">
        {{ service.description }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'ServiceCard',
    props: {
      service: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.card__container:not(:last-child) {
  margin-bottom: 2rem;
}
  .card__container:hover {
    background: #2EB359;
    color: white;
    transition: all 350ms ease;

    .service__title:before {
      width: 30px;
      background: white;
    }

    .service__description {
      color:white;
    }

    .service__icon {
      color: white;
    }
  }

  .service__title {
    position: relative;
    text-transform: uppercase;
  }

  .service__title:before {
    position: absolute;
    content: "";
    left: 1rem;
    bottom: 10px;
    height: 3px;
    width: 50px;
    background: #27AE60;
    transition: all 0.4s ease;
  }

  .service__icon {
    color: #FAFAFA;
    position: absolute;
    top: 15px;
    right: 15px;
    transition: all 0.1s ease;
  }
</style>
