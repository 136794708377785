import { apolloClient } from "@/addons";
import { requestQueryHomepageContent } from "@/gqlRequests";

interface IState {
  homepageContent: IHomepageContent
}

interface IHomepageContent {
  whatWeDoTitle: string;
  whatWeDoDescription: string;
  endToEndAppDevTitle: string;
  endToEndAppDevDescription: string;
  endToEndAppDevMedia: {url: string};
  webAppServiceLink: {text: string};
  webAppServiceTitle: string;
  webAppServiceDescription: string;
  webAppMedia: {url: string}
}

export default {
  state: {
    homepageContent: null,
  },
  getters: {
    homepageContent(state: IState) {
      return state.homepageContent;
    },
  },
  mutations: {
    SET_HOMEPAGE_CONTENT(state: IState, payload: IHomepageContent) {
      state.homepageContent = payload;
    },
  },
  actions: {
    async getContent({ commit }: any, payload: any): Promise<IHomepageContent> {
      try {
        const { data } = await await apolloClient.query({
          query: requestQueryHomepageContent,
        });

        const { homepage = {} } = data;

        commit("SET_HOMEPAGE_CONTENT", homepage);

        return homepage;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
