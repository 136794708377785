import gql from "graphql-tag";

export * from "./auth";

export * from "./homepage";

export const requestQueryGetTeammates = gql`
    query FetchTeamMembers($active: Boolean = true, $sort: String = "oid:DESC", $joined: String) {
        teamMembers(where: { active: $active, joined_gte: $joined }, sort: $sort, limit: 4) {
            id
            name
            active
            description
            bio
            joined
            favorite_tech
            title
            oid
            user_image {
                url
            }
        }
    }
`;

export const requestQueryGetArticles = gql`
    query FetchArticles {
        articles {
            id
            title
            link
            image {
                url
            }
            content
            publication
            displayOnHomepage
            featuredArticle
            published
            youtubeLink
        }
    }
`

export const requestQueryGetSiteContent = gql`
    query FetchSiteContent {
        siteContents {
            id
            uid
            key
            content
        }
    }
`;

export const requestQueryGetUserProfiles = gql`
    query FetchUserProfiles($userId: String) {
        userProfiles(where: { users_permissions_user: [$userId] }) {
            id
            ratePerHour
            users_permissions_user {
                email
                username
                id
            }
        }
    }
`;

export const requestQueryGetUsersByRoleName = gql`
    query FetchUsers($roleName: String) {
        users(where: { role: { name: $roleName } }) {
            id
            username
            user_profile {
                ratePerHour
            }
        }
    }
`;

export const requestMutationCreateUserProfile = gql`
    mutation CreateUserProfile($input: createUserProfileInput!) {
        createUserProfile(input: $input) {
            userProfile {
                id
            }
        }
    }
`;

export const requestMutationUpdateUserProfile = gql`
    mutation UpdateUserProfile($input: updateUserProfileInput!) {
        updateUserProfile(input: $input) {
            userProfile {
                id
                ratePerHour
            }
        }
    }
`;

export const mutationCreateApplicant = gql`
    mutation CreateApplicant($input: createApplicantInput!) {
        createApplicant(input: $input) {
            applicant {
                id
                firstName
                lastName
                emailAddress
                desiredRate
                resume {
                    name
                    size
                    type
                }
                coverLetter {
                    name
                    size
                    type
                }
            }
        }
    }
`;
