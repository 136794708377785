import gql from 'graphql-tag'

export const mutationRegisterUser = gql`
    mutation Register($input: UsersPermissionsRegisterInput!) {
      register (input: $input) {
        jwt
        user {
          id
          email
          username
          role {
            id
            name
          }
        }
      }
    }
`

export const mutationLoginUser = gql`
    mutation Login($input: UsersPermissionsLoginInput!) {
      login (input: $input) {
        jwt
        user {
          id
          email
          username
          role {
            id
            name
          }
        }
      }
    }
`
