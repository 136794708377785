<template>
  <Section title="News" max-width-container="100%" name="press">
    <img src="/img/news-triangle.svg" id="newsTriangle" />
    <v-row>
      <v-col class="d-flex flex-column align-center">
        <v-row v-for="article in homePageArticles" v-bind:key="article.id" class="mb-10 article">
          <v-col cols="12" md="3">
            <img :src="article.image.url" />
          </v-col>
          <v-col cols="12" md="9">
            <a :href="article.link"><h3>{{article.title}}</h3></a>
            <p>
              <vue-markdown>
                {{article.content}}
              </vue-markdown>
            </p>
          </v-col>
        </v-row>
        <v-btn to="/press" class="black white--text rounded-pill px-10 py-5">More</v-btn>
      </v-col>
    </v-row>
  </Section>
</template>
<script>
import Section from "../../../ui/Section";
import VueMarkdown from "vue-markdown";

export default {
  name: 'PressSection',
  components: {
    Section,
    VueMarkdown
  },
  data() {
    return {
      homePageArticles: []
    }
  },
  props: {
    articles: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.homePageArticles = this.articles.filter(article => article.displayOnHomepage === true)
  }
}
</script>
<style lang="scss" scoped>
#press {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow-y: visible;
  overflow-x: hidden;

  #newsTriangle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    max-width: 80%;
  }

  #bottomTriangle {
    position: absolute;
    right: 0;
    bottom: -50%;
    z-index: 3 !important;
  }
}
.article {
  padding-left: 3rem;
  padding-right: 3rem;
  z-index: 2;
  img {
    border-radius: 25px;
    max-width: 100%;
  }
}
</style>