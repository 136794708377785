interface IState {
  drawer: boolean
  mini: boolean
  drawerImage: true
}

export default {
  state: {
    drawer: null,
    mini: false,
    drawerImage: false
  },
  mutations: {
    toggleDrawer(state: IState, payload: boolean): void {
      state.drawer = !state.drawer
    },
    toggleMini(state: IState): void {
      state.mini = !state.mini
    },
  },
  getters: {
    drawer(state: IState): boolean {
      return state.drawer
    },
    mini(state: IState): boolean {
      return state.mini
    },
    drawerImage(state: IState): boolean {
      return state.drawerImage
    },
  },
  actions: {}
}
