import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const theme = {
  primary: '#4CAF50',
  secondary: '#9C27b0',
  accent: '#e91e63',
  info: '#00CAE3',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
}

const opts = {
  breakpoint: { mobileBreakpoint: 960 },
  theme: {
    dark: false,
    themes: {
      light: theme,
    }
  },
}

export default new Vuetify(opts)