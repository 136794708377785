<template>
  <div>
    <our-approach :title="homepageContent.whatWeDoTitle" :description="homepageContent.whatWeDoDescription"/>
    <flexibility-at-any-stage/>
    <products-and-services/>
    <collaborative-and-scientific :title="homepageContent.collaborativeTitle"
                                  :description="homepageContent.collaborativeDescription"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import OurApproach from "@/components/pages/home/about-us/OurApproach";
import FlexibilityAtAnyStage from "@/components/pages/home/about-us/Flexibility";
import ProductsAndServices from "@/components/pages/home/about-us/ProductsAndServices";
import CollaborativeAndScientific from "@/components/pages/home/about-us/CollaborativeAndScientific";

export default {
  name: "AboutUsSection",
  components: {
    CollaborativeAndScientific,
    ProductsAndServices,
    FlexibilityAtAnyStage,
    OurApproach,
  },
  computed: {
    ...mapGetters("homepageStore", ["homepageContent"]),
  }
};
</script>