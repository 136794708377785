import { apolloClient } from "@/addons"
import { requestQueryGetCareerOpportunities, requestQueryGetCareerOpportunitySkills } from "@/gqlRequests/homepage"
import { get } from "lodash"
interface ICareerOpportunity {
    id: string
    title: string
    description: string
    summary: string
    salary: string
    uid: number
    active: boolean
    position_image: string
}

interface ICareerOpportunity {
    id: string
    skill: string
}

interface IGetCareerOpportunityResponse {
    data: ICareerOpportunity
}

interface ICareerOpportunities {
    careerOpportunities: ICareerOpportunity[]
}

interface IGetNewslettersPayload {
    title_containes?: string
}

export default {
    state: {
        careerOpportunities: []
    },
    mutations: {
        SET_CAREEROPPORTUNITIES(state: ICareerOpportunities, payload: ICareerOpportunity[]) {
            state.careerOpportunities = payload
        }
    },
    actions: {
        async getCareerOpportunities(_: any, payload: IGetNewslettersPayload): Promise<ICareerOpportunity[]> {
            const { data } = await apolloClient.query<IGetCareerOpportunityResponse>({
                query: requestQueryGetCareerOpportunities,
                variables: payload
            })
            const careerOpportunities = get(data, 'careerOpportunities', [])
            
            return careerOpportunities
        },
        async getCareerOpportunitySkills(_: any): Promise<ICareerOpportunity[]> {
            const { data } = await apolloClient.query<{data: ICareerOpportunity}>({
                query: requestQueryGetCareerOpportunitySkills
            })
            const careerOpportunitySkills = get(data, 'careerOpportunitySkills', [])
            
            return careerOpportunitySkills
        },
        setCareerOpportunities({commit}: { commit: any }, payload: ICareerOpportunity[]) {
            commit('SET_CAREEROPPORTUNITIES', payload)
        },
        resetStore({commit}: {commit: any}) {
            commit('SET_CAREEROPPORTUNITIES', [])
        }
    },
    getters: {
        getActiveCareerOpportunities(state: ICareerOpportunities) {
            return state.careerOpportunities
        }
    }
}