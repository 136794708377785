import {apolloClient} from './apollo'
import store from '@/store';
import {
    requestQueryGetSiteContent,
    requestQueryGetCareerOpportunities,
    requestQueryGetClients
} from '../gqlRequests/index';

class ApolloClientApi {
    initAll() {
        this.getSiteContentsList()
    }
    getCareerOpportunitiesList() {
        apolloClient.query({query: requestQueryGetCareerOpportunities}).then((response) => {
            const { careerOpportunities } = response.data
            store.dispatch('careerOpStore/setCareerOpportunities', careerOpportunities)
        })
    }
    getClientsList() {
        apolloClient.query({ query: requestQueryGetClients }).then( (response) => {
            const { clients } = response.data
            store.dispatch('clientsStore/setClients', clients)
        })
    }
    getSiteContentsList() {
        apolloClient.query({ query: requestQueryGetSiteContent }).then( (response) => {
            const { siteContents } = response.data
            store.dispatch('siteContentsStore/setSiteContents', siteContents)
        })
    }
}

export const apolloClientApi = new ApolloClientApi()