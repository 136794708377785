import gql from "graphql-tag";

export const mutationCreateContactUs = gql`
  mutation CreateContactUs($input: createContactUsInput!) {
    createContactUs(input: $input) {
      contactUs {
        name
        phone
        email
        company
        message
        id
      }
    }
  }
`;
