<template>
  <div class="testimonial__container" data-aos="zoom-in">
    <div class="box sb3 black--text">
      {{client.testimonial}}
    </div>
    <v-row class="align-center">
      <v-col class="shrink">
        <v-avatar
          color="primary"
          size="56"
        >
          <img v-if="clientAvatar" :src="clientAvatar" :alt="client.name" @error="showClientAvatar = false">
          <span v-else class="white--text text-h5">{{ clientInitials }}</span>
        </v-avatar>
      </v-col>
      <v-col>
        <div class="client__name headline">{{ client.name }}</div>
        <div class="client__position white--text">{{ client.position }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'TestimonialCard',
    props: {
      client: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        showClientAvatar: true
      }
    },
    computed: {
      clientInitials() {
        const name = this.client?.name

        if (name.length < 3) {
          return name
        }

        return name.split(" ").map((n)=>n[0]).join(".")
      },
      clientAvatar() {
        const avatar = this.client?.logo_image[0]?.url

        if (!avatar) {
          return null
        }

        return this.$getMediaURL(avatar)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .box {
    width: 100%;
    margin: 30px auto;
    background: #fff;
    padding: 20px;
    position: relative;
    min-height: 5rem;
  }

  .sb3 {
    border-radius: 25px;
  }

  .sb3:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid #fff;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
    border-bottom: 10px solid transparent;
    left: 30px;
    bottom: -20px;
  }

  .client__name {
    color: #27AE60;
  }
</style>
