<template>
  <v-row class="focus-item-list">
    <v-col v-for="item in items" :key="item.id" cols="12" sm="4" md="4">
      <div class="focus-item">
        <div class="image">
          <img :src="imageURL(item.media)" alt="item.title" />
        </div>
        <h3 class="text-center">{{ item.title }}</h3>
        <p class="text-justify text-md-left">
          <VueMarkdown>
            {{ item.description }}
          </VueMarkdown>
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import VueMarkdown from 'vue-markdown'
export default {
  name: "FocusItemList",
  components: {
    VueMarkdown
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    imageURL(media) {
      if (!media) {
        return ""
      }
      return this.$getMediaURL(media.url);
    },
  }
};
</script>

<style lang="scss" scoped>
.focus-item-list {
  .focus-item {
    padding: 0 1rem;
    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      text-align: left;
      margin-bottom: 1rem;
    }
    p {
      font-weight: 500;
    }
    .image {
      width: 100%;
      max-width: 15rem;
      margin: 0 auto;
      height: 180px;
      display: flex;
      img {
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
}

@media (min-width: 600px) {
  h3 {
    min-height: 72px;
  }
}
</style>
