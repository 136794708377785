import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import './plugins'
import router from './router'
import store from './store'
import "./assets/scss/index.scss";
import AOS from 'aos'
import 'aos/dist/aos.css'
import helpers from './plugins/helpers'
import AlertModal from './components/ui/AlertModal.vue';


const plugin = {
  install () {
    Vue.prototype.$h = helpers,
    Vue.prototype.$api_url = process.env.VUE_APP_API_URL
    Vue.prototype.$getMediaURL = (url = "") => {
      if (url && url.includes("s3.amazonaws")) {
        return url
      }

      return process.env.VUE_APP_API_URL + url;
    }
  }
}

Vue.use(plugin)
Vue.component("AlertModal", AlertModal);

Vue.config.productionTip = false

new Vue({
  created () {
    AOS.init()
    const locale = localStorage.getItem('locale') || 'en'
    const html = document.documentElement
    html.setAttribute('lang', locale)
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
