<template>
  <div class="section-item">
    <line-on-sides class="section-title">
      {{ title }}
    </line-on-sides>
    <v-row class="content" align="center">
      <v-col cols="12" md="6">
        <div class="image-container" v-if="media">
          <img :src="$getMediaURL(media.url)" alt="illustration"/>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <h3 class="font-weight-bold">
          {{ subtitle }}
        </h3>
        <p class="description">
          <VueMarkdown>
            {{ description }}
          </VueMarkdown>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LineOnSides from "@/components/ui/LineOnSides";
import VueMarkdown from "vue-markdown";
export default {
  name: 'EndToEndAppDev',
  props: {
    title: String,
    subtitle: String,
    description: String,
    media: Object
  },
  components: {
    LineOnSides,
    VueMarkdown
  }
}
</script>