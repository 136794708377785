import gql from "graphql-tag";

export const requestQueryGetClients = gql`
  query FetchClients($display: Boolean = true, $locale: String, $sort: String = "oid:ASC") {
    clients(sort: $sort, where: { display: $display }, locale: $locale) {
      id
      oid
      uid
      name
      description
      obtained_by
      position
      display
      logo_image {
        url
      }
      testimonial
    }
  }
`;

export const requestQueryClientsPageContent = gql`
  query FetchClientsPageContent {
    clientsPage {
      id
      page_title
      page_illustration {
        url
      }
      projects {
        id
        title
        description
        background_color
        media {
          url
        }
      }
      client_reviews {
        id
        name
        title
        message
        avatar {
          url
        }
      }
    }
  }
`;
