import { apolloClientFactory } from "@/addons";
import { requestQueryTrustedByList } from "@/gqlRequests";

export default {
  state: {
    trustedByList: [],
  },
  getters: {
    trustedByList(state: any) {
      return state.trustedByList || [];
    },
  },
  mutations: {
    SET_TRUSTED_BY_LIST(state: any, payload: any) {
      state.trustedByList = payload;
    },
  },
  actions: {
    async getTrustedByList({ commit }: any) {
      const response = await apolloClientFactory().query({
        query: requestQueryTrustedByList,
      });
      const { trustedBy } = response.data;
      commit("SET_TRUSTED_BY_LIST", trustedBy?.trusted_by_list || []);
      return trustedBy;
    },
  },
};
