<template>
  <div class="content-container">
    <v-container>
      <h2 class="title-text text-right text-md-center flexibility-header">Flexibility at any stage</h2>
      <v-row class="card-container flex-column flex-md-row">
        <v-col class="custom-card flex-column d-flex" v-if="!currentCard || currentCard === 1" :class="{'col-12': currentCard === 1}">
          <div class="d-flex align-start">
            <div class="title-text">Solutions for startups</div>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="currentCard = 0" v-show="currentCard">mdi-close</v-icon>
          </div>
          <p class="IfParagraph" v-if="currentCard">
            We create scalable applications: when you grow, your applications grow with you.
            Easily scalable means we can adapt the backend to user base growth. Maintaining performance and user experience shouldn’t be a hassle. 
            We provide counseling if you’re looking to increase your client base: we will take your ideas and turn them into practical courses of action. 
            Improve customer loyalty and increase brand awareness and brand recognition by offering clients attractive, easy-to-use applications that have that signature flair.
          </p>
          <p class="ElseParagraph" v-else>We create scalable applications: when you grow, your applications grow with you.</p>
          <v-btn rounded @click="currentCard = 1" class="learn-more-btn text-decoration-none black--text font-weight-medium" v-show="!currentCard">
            Learn More
          </v-btn>
        </v-col>
        <v-col class="custom-card flex-column d-flex" v-if="!currentCard || currentCard === 2" :class="{'col-12': currentCard === 2}">
          <div class="d-flex align-start">
            <div class="title-text">Solutions for small to medium businesses</div>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="currentCard = 0" v-show="currentCard">mdi-close</v-icon>
          </div>
          <p  class="IfParagraph" v-if="currentCard">
            Taking an idea up off the ground is a challenge, and we at Mathison Projects know how delicate it is to entrust a third-party provider with a new project. 
            That’s why we make sure your idea complies with user demands and market trends. But not only that: we take what’s great from tried-and-tested legacy applications and forge that into new, innovative application projects. 
            We take into account your time and budget constraints to make sure you’re not spending where you shouldn’t need to, while still obtaining a top-quality product.
          </p>
          <p class="ElseParagraph" v-else>We take what’s great from tried-and-tested legacy applications and forge that into new, innovative application projects.</p>
          <v-btn rounded @click="currentCard = 2" class="learn-more-btn text-decoration-none black--text font-weight-medium" v-show="!currentCard">
            Learn More
          </v-btn>
        </v-col>
        <v-col class="custom-card flex-column d-flex" v-if="!currentCard || currentCard === 3" :class="{'col-12': currentCard === 3}">
          <div class="d-flex align-start">
            <div class="title-text">Solutions for enterprises</div>
            <v-spacer></v-spacer>
            <v-icon color="black" @click="currentCard = 0" v-show="currentCard">mdi-close</v-icon>
          </div>
          <p class="IfParagraph" v-if="currentCard">You’re already on the cloud, you already have a user base: we know our way around corporate asks. 
            We’re not afraid to tackle huge databases, and we comply with the highest security standards set by your organization, not ours. 
            We interact with your team of specialists so we can collaborate together to ensure your product is aligned with your company–and both our quality measures. 
            We’re highly organized and well-versed in SCRUM and Agile methodologies and can handle a (very) long backlog reducing time-to-market and optimizing tasks every single day.</p>
          <p class="ElseParagraph" v-else>We interact with your team of specialists so we can collaborate together to ensure your product is aligned with your company–and both our quality measures.</p>
          <v-btn rounded @click="currentCard = 3" class="learn-more-btn text-decoration-none black--text font-weight-medium" v-show="!currentCard">
            Learn More
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'FlexibilityAtAnyStage',
  data: () => ({
    currentCard: 0
  })
}
</script>
<style lang="scss">
.flexibility-header {
  font-size: 1.75em !important;
}
.content-container {
  background-image: url('/img/flexibility-at-any-stage.svg');
  background-size: cover;
  min-height: 100%;
  padding: 1.3rem;
  background-position: 30% 2.5em;

  @include screen('xl') {
    padding: unset;
    padding-left: 6vw;
    padding-right: 6vw;
    padding-top: 10vh;
    background-size: 100vw;
    background-position: 80% 100%;
  }
}

@include screen('xl') {
  .flexibility-header {
    margin-top: -12vh;
  }
}

.card-container {
  gap: 1rem;
  padding: 5rem 0;
  position: relative;
  .custom-card {
    background-color: white;
    padding: 1.5rem;
    gap: .5rem;
    position: relative;
    -webkit-box-shadow: 3px -3px 10px 0px rgb(0, 0, 0, 0.5);
    -moz-box-shadow: 3px -3px 10px 0px rgb(0, 0, 0, 0.5);
    box-shadow: 3px -3px 10px 0px rgb(0, 0, 0, 0.5);

    @include screen('md') {      
      font-size: .8rem;
    }

    p {
      margin: .5rem 0;
    }

    a {
      position: absolute;
      bottom: 1rem;
      right: 1.5rem;
    }

    h3 {
      font-size: 1.17rem;
      font-weight: 600;
      
      @include screen('md') {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }
   .IfParagraph{
    @media only screen and (max-width: 768px) and (min-width: 425px)  {
      min-height: 250px;
      font-size: 1.1rem;
    }
 }

 .ElseParagraph{
  @media only screen and (max-width: 768px) and (min-width: 425px) {
   min-height: 100px;
  font-size: 1rem;
   }
 }
}
.learn-more-btn {
  margin-left: auto;
  max-width: min-content !important;
  margin-top: auto;
}
</style>