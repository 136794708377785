import HomePage from "@/views/HomePage.vue";

export default [
  {
    path: '/',
    component: () => import('../layouts/DefaultLayoutComponent.vue'),
    children: [
      {
        path: '/',
        name: 'base-home',
        component: () => import('../views/HomePage.vue'),
        meta: {
          title: "Home"
        }
      },
      {
        path: '/about',
        name: 'base-about',
        component: () => import('../views/AboutPage.vue'),
        meta: {
          title: "About",
          breadcrumbItems: [
            {
              text: 'Home',
              disabled: false,
              href: '/',
            },
            {
              text: 'About us',
              disabled: true
            }
          ]
        }
      },
      {
        path: '/discord',
        name: 'discord',
        component: HomePage,
        beforeEnter(): void {
          window.location.href = "https://discord.com/invite/j2NHJ7Xdva"
        }
      },
      {
        path: '/contact-us',
        name: 'base-contact',
        component: () => import('../views/ContactPage.vue'),
        meta: {
          title: "Contact",
          breadcrumbItems: [
            {
              text: 'Home',
              disabled: false,
              href: '/',
            },
            {
              text: 'Contact',
              disabled: true
            }
          ]
        }
      },
      {
        path: '/access',
        name: 'base-access',
        component: () => import('../views/AccessPage.vue'),
        meta: {
          title: "Access"
        }
      },
      {
        path: '/privacy-policy',
        name: 'base-privacy-policy',
        component: () => import('../views/PrivacyPolicyPage.vue'),
        meta: {
          title: "Privacy Policy"
        }
      },
      {
        path: '/terms-of-use',
        name: 'base-terms-of-use',
        component: () => import('../views/TermsOfUserPage.vue'),
        meta: {
          title: "Terms of Use"
        }
      },
      {
        path: '/products-and-services',
        name: 'products-and-services',
        component: () => import('../views/ProductsPage.vue'),
        meta: {
          title: "Products & Services"
        }
      },
      {
        path: '/press',
        name: 'press',
        component: () => import('../views/PressPage.vue'),
        meta: {
          title: 'Press'
        }
      },
      {
        path: '/careers',
        name: 'base-careers',
        component: () => import('../views/CareersPage.vue'),
        meta: {
          title: "Careers",
          breadcrumbItems: [
            {
              text: 'Home',
              disabled: false,
              href: '/',
            },
            {
              text: 'Careers',
              disabled: true
            }
          ]
        }
      },
      {
        path: '/investors',
        name: 'base-investors',
        component: () => import('../views/InvestorsPage.vue'),
        meta: {
          title: "Investors"
        }
      },
      {
        path: '/services',
        name: 'services',
        component: () => import('../views/services/ServicesPage.vue'),
        meta: {
          title: "Services",
        },
      },
      {
        path: 'services/web-services',
        name: 'web-services',
        component: () => import('../views/services/WebServices.vue'),
        meta: {
          title: "Home"
        }
      },
      {
        path: 'reimagining-charity',
        name: 'Reimagining Charity',
        component: () => import('../views/ReimaginingCharity.vue'),
        meta: {
          title: "Reimagining Charity"
        }
      }
    ]
  }
]
