import { apolloClient } from "@/addons"
import { requestMutationUpdateUserProfile } from '../../gqlRequests/index';
import {
  requestQueryGetUserProfiles,
  requestMutationCreateUserProfile,
  requestQueryGetUsersByRoleName
} from '@/gqlRequests'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createUserProfile(context: any, payload: any) {
      try {
        const { userId } = payload
        const { data } = await apolloClient
          .query({
            query: requestMutationCreateUserProfile,
            variables: {
              input: {
                data: {
                  users_permissions_user: userId
                }
              }
            }
          })
      } catch (error: any) {
        throw new Error(error)
      }
    },
    async getUsers() {
      try {
        const { data } = await apolloClient
          .query({ query: requestQueryGetUserProfiles })

        return data.userProfiles
      } catch (error: any) {
        throw new Error(error)
      }
    },
    async getUsersByRoleName({ commit }: any, roleName: string) {
      try {
        const { data: {
          users
        } } = await apolloClient
          .query({
            query: requestQueryGetUsersByRoleName, variables: {
              roleName
            }
          })
        
        return users
      } catch (error: any) {
        throw new Error(error)
      }
    },
    async updateUserRatePerHour(context: any, payload: any) {
      try {
        const { userId, ratePerHour } = payload
        const { data } = await apolloClient
          .query({
            query: requestMutationUpdateUserProfile,
            variables: {
              input: {
                where: {
                  id: userId,
                },
                data: {
                  ratePerHour
                }
              }
            }
          })

      } catch (error: any) {
        console.error(error)
        throw new Error(error)
      }
    }
  }
}