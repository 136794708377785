import {apolloClient} from "@/addons";
import {requestQueryGetArticles} from "@/gqlRequests";

interface IArticle {
    id: string;
    content: string;
    link: string;
    title: string;
    youtubeLink: string;
}

interface IArticles {
    articles: IArticle[];
}

export default {
    state: {
        articles: []
    },
    mutations: {
        SET_ARTICLES(state: IArticles, payload: any): void {
            state.articles = payload;
        }
    },
    actions: {
        async getArticles({commit}: any): Promise<IArticle[]> {
            const response = await apolloClient.query({
                query: requestQueryGetArticles,
            });
            const {articles} = response.data;
            commit("SET_ARTICLES", articles);

            return articles;
        },
        setArticles({commit}: { commit: any }, payload: IArticle[]): void {
            commit("SET_ARTICLES", payload);
        },
        resetStore({commit}: { commit: any }): void {
            commit("SET_ARTICLES", []);
        },
    },
    getters: {
        getAllArticles(state: IArticles): IArticle[] | [] {
            return state.articles || [];
        },
    },
}