import gql from "graphql-tag";

export const requestQueryGetCareerOpportunities = gql`
  query FetchCareerOpportunities(
    $active: Boolean = true
    $title_contains: String
    $salary_gte: Float
    $salary_lte: Float
    $career_opportunity_skills_in: [String]
    $limit: Int
    $start: Int
  ) {
    careerOpportunities(
      where: {
        active: $active
        title_contains: $title_contains
        salary_gte: $salary_gte
        salary_lte: $salary_lte
        career_opportunity_skills_in: $career_opportunity_skills_in
      }
      limit: $limit
      start: $start
    ) {
      id
      title
      active
      description
      summary
      salary
      uid
      position_image {
        url
      }
      career_opportunity_skills {
        id
        skill
        color
      }
    }
  }
`;

export const requestQueryGetCareerOpportunitySkills = gql`
  query FetchCareerOpportunitySkills {
    careerOpportunitySkills {
      id
      skill
      color
    }
  }
`;

export const requestQueryCareersPageContent = gql`
  query FetchCareersPageContent {
    careersPage {
      id
      page_title
      page_subtitle
      page_illustration {
        url
      }
    }
  }
`;
