<template>
  <div id="bg-container" class="d-flex flex-column align-center justify-center">
    <v-container>
      <h2 class="text-center text-title white--text font-weight-bold">{{ title }}</h2>
      <p class="text-center white--text mt-5 mx-auto intro-text">
        <VueMarkdown>{{ description }}</VueMarkdown>
      </p>
    </v-container>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: 'CollaborativeAndScientific',
  props: {
    title: String,
    description: String
  },
  components: {
    VueMarkdown,
  }
}
</script>
<style lang="scss">
#bg-container {
  background-image: url("/img/collaborative-approach.webp");
  background-size: cover;
  background-position: center center;
  min-height: 500px;

  p {
    max-width: 70vw;
    font-weight: 300;
  }

  @include screen('lg') {
    background-size: 100%;
    p {
      max-width: 50vw;
    }
  }
}
@include screen('onlySm') {
  p {
    font-size: 1.1em;
    line-height: 1.5em;
  }
  h2 {
    font-size: 2em;
  }
}
</style>