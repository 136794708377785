<template>
  <v-snackbar
    :value="value"
    @input="e => $emit('input', e)"
    :color="color()"
    top
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="$emit('input', false)"
      >
        X
      </v-btn>
    </template>
  </v-snackbar>
</template>
 
<script>
export default {
  props: {
    text: {
      type: String
    },
    value: {
      type: Boolean
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      alertEnum: {
        danger: { label: 'danger', color: 'red'},
        success: { label: 'success', color: 'green'},
        default: { label: 'default', color: 'blue'},
        caution: { label: 'caution', color: 'orange'}
      }
    }
  },
  methods: {
    color() {
      const { type, alertEnum} = this;
      let color = '';
      switch (type) {
        case alertEnum.danger.label:
          color = alertEnum.danger.color
          break;
        case alertEnum.success.label:
          color = alertEnum.success.color;
          break;
        case alertEnum.default.label:
          color = alertEnum.default.color;
          break;
        case alertEnum.caution.label:
          color = alertEnum.danger.color;
          break;
        default:
          color = alertEnum.default.color;
      }
      
      return color;
    }
  }, 
  watch: {
    value() {
      this.color()
    }
  }
}
</script>
