<template>
  <Section data-aos="zoom-in">
    <h1 class="text-center display-3 font-weight-medium">
      Get in Touch
    </h1>
    <h2 class="text-center headline mt-3">
      Want to get in touch? We'd love to hear from you!
    </h2>

    <div class="d-flex justify-center mt-10">
      <v-btn
        class="white--text"
        color="#2EB359"
        elevation="2"
        rounded
        x-large
        :to="{path: '/contact-us', hash: '#form'}"
      >
      Schedule Consultation
      </v-btn>
    </div>
  </Section>
</template>

<script>
import Section from '../../ui/Section'
  export default {
    name: 'ContactUsSection',
    components: {
      Section,
    },
    data() {
      return {
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
