<template>
  <v-row class="trusted-row">
    <v-col id="purpleBG" class="my-3 py-12 px-md-16 d-flex flex-column align-center justify-center text-center" cols="12" md="6">
      <h2 class="title-text white--text">Trusted by <br class="d-none d-md-inline"/> Top Companies</h2>
      <img :src="etsy.logo_image[0].url" :height="$vuetify.breakpoint.mdAndUp ? 200 : 90"/>
      <p class="white--text text-justify font-weight-light ultrawide-trusted-by">
        <VueMarkdown>
          {{ etsy.testimonial }}
        </VueMarkdown>
      </p>
    </v-col>
    <v-col cols="12" md="6" class="d-flex flex-wrap py-16 align-center justify-center" id="logos">
      <img alt="company logos" v-for="client in clientsWithLogos" v-bind:key="client.id"
           :v-if="!!client.logo_image.length"
           :src="client.logo_image[0].url" class="col-3"/>
    </v-col>
  </v-row>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "TrustedBy",
  components: {
    VueMarkdown
  },
  props: {
    clients: {
      type: Array,
      required: true
    }
  },
  computed: {
    etsy() {
      return this.clients.filter(client => client.name === 'Etsy')[0]
    },
    clientsWithLogos() {
      let hasLogo = this.clients.filter(client => client.logo_image.length > 0)
      return hasLogo.filter(client => client.name !== 'Etsy')
    },
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    }
  },
}
</script>

<style lang="scss">
@media only screen and (min-width: 2000px) {
  .ultrawide-trusted-by {
    padding-right: 5vw !important;
  }
} 
#purpleBG {
  background-image: linear-gradient(to bottom right, #3D46A8, #923559);
  min-height: 600px;
  position: relative;
  padding-right: 150px !important;
  gap: 0.5rem;

  p {
    line-height: normal;
    padding: rem;
  }

  @media only screen and (max-width: 900px) {
    padding-right: 0px !important;
    min-height: 400px;
    &:after {
      display: none;
    }
    p {
      padding: 0 2rem;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -70%;
    height: 100%;
    width: 100%;
    background-color: white;
    transform-origin: 100% 0;
    transform: skew(20deg);
    z-index: 1;
  }
}

#logos {
  gap: 1rem;
  z-index: 2;
  @media only screen and (max-width: 900px) {
    padding-right: 0px !important;
  }
}
.trusted-row {
  margin-left: 6vw;
  margin-right: 6vw;
  @include screen('sm') {
    margin-left: unset;
    margin-right: unset;
  }
}
</style>