<template>
  <v-btn v-if="buttonVisible" rounded class="backToTopButton" @click="scrollToTop">
    ^
  </v-btn>
</template>
<script>
export default {
  name: 'ScrollToTopButton',
  data() {
    return {
      buttonVisible: false
    }
  },
  created() {
    window.addEventListener('scroll', this.toggleAppearance)
  },
  destroyed() {
    window.removeEventListener('scroll', this.toggleAppearance)
  },
  methods: {
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    toggleAppearance() {
      this.buttonVisible = document.body.scrollTop > 100 || document.documentElement.scrollTop > 100;
    }
  }
}
</script>
<style lang="scss" scoped>
.backToTopButton {
  position: fixed !important;
  bottom: 2rem;
  right: 2rem;
  width: 75px;
  height: 100px;
  z-index: 3000;
	box-shadow: 0 12px 16px 0 rgba(0,0,0,0.5);

  @include screen('xl') {
    width: 100px;
  }

  @media only screen and (max-width: 768px) {
    bottom: 5%;
    right: 10%;
    font-size: 1.5rem !important;
  }
}
</style>